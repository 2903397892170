import React from "react";
import { Box, Typography } from "@mui/material";
import { gql } from "@apollo/client";
import { useMultisignatureWalletDetailsQuery } from "@backed-fi/graphql";
import { useParams } from "react-router-dom";
import { LoaderSection } from "@backed-fi/compound";
import { MultisignatureTransactionsTable } from "./components/MultisignatureTransactionsTable";
import { ProposeTransactionDialog } from "./components/ProposeTransaction";

gql`
  query multisignatureWalletDetails($walletId: String!) {
    multisignatureWallet(id: $walletId) {
      title
    }
  }
`;

export const MultisignatureWalletDetailsOverviewPage: React.FC = () => {
  const params = useParams<{ id: string }>();

  const { data: wallet, loading } = useMultisignatureWalletDetailsQuery({
    variables: {
      walletId: params.id!,
    },
  });

  return (
    <Box sx={{ marginRight: "3em" }}>
      <Box>
        {loading && (
          <LoaderSection description="Fetching multisignature details" />
        )}
        {!loading && wallet && (
          <>
            <Box>
              <ProposeTransactionDialog walletId={params.id!} />
            </Box>
            <Box>
              <Box>
                <Typography sx={{ marginBottom: "16px" }} variant="title">
                  Transactions
                </Typography>
                <MultisignatureTransactionsTable
                  walletId={params.id!}
                />
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};
