import React from "react";
import { gql } from "@apollo/client";
import { DataGrid } from "@mui/x-data-grid";
import { Box, IconButton, LinearProgress, Tooltip, Typography } from "@mui/material";
import DetailsIcon from '@mui/icons-material/VisibilityRounded';

import { BlockchainNetworkLabel } from "@backed-fi/shared/components/compound/BlockchainNetworkLabel";

import {
  MultisignatureWalletTransactionStatus,
  useMultisignatureWalletTransactionsQuery,
} from "@backed-fi/graphql";
import { AddSignature } from "./AddSignature";

gql`
  query multisignatureWalletTransactions($walletId: String!) {
    multisignatureWallet(id: $walletId) {
      requiredSignatures
      transactions {
        id
        network
        status
        nonce
        description
        tenderlySimulation
        signatures {
          signerAddress
        }
      }
    }
  }
`;

// region Props

interface Props {
  walletId: string;
}

// endregion

export const MultisignatureTransactionsTable: React.FC<Props> = ({
  walletId,
}) => {

  // region Networking

  const { data, loading } = useMultisignatureWalletTransactionsQuery({
    variables: {
      walletId,
    }
  });

  const wallet = data?.multisignatureWallet;

  // endregion

  return (
    <Box
      sx={{
        height: "100%",
      }}
    >
      <Box
        sx={{
          overflowX: "scroll",
        }}
      >
        <DataGrid
          autoHeight
          slots={{
            loadingOverlay: LinearProgress,
          }}
          loading={loading}
          rows={wallet?.transactions ?? []}
          columns={[
            {
              flex: 2,
              field: "network",
              headerName: "Network",
              renderCell: ({ value }) => {
                return <BlockchainNetworkLabel network={value} />;
              },
            },
            {
              flex: 2,
              field: "description",
              headerName: "Description",
              renderCell: ({ value }) => (
                <Typography
                  sx={{
                    height: "21px",
                    gap: "0.25rem",
                    display: "flex",
                    alignItems: "center",
                    fontSize: 14,
                  }}
                >
                  {value}
                </Typography>
              ),
            },
            {
              flex: 2,
              field: "status",
              headerName: "Status",
              renderCell: ({ value }) => (
                <Typography
                  sx={{
                    height: "21px",
                    gap: "0.25rem",
                    display: "flex",
                    alignItems: "center",
                    fontSize: 14,
                  }}
                >
                  {value}
                </Typography>
              ),
            },
            {
              flex: 2,
              field: "signatures",
              headerName: "Signatures",
              renderCell: ({ row }) => (
                <Typography
                  sx={{
                    height: "21px",
                    gap: "0.25rem",
                    display: "flex",
                    alignItems: "center",
                    fontSize: 14,
                  }}
                >
                  {row.signatures.length} / {wallet!.requiredSignatures}
                </Typography>
              )
            },
            {
              flex: 2,
              field: "nonce",
              headerName: "Nonce",
              renderCell: ({ value }) => (
                <Typography
                  sx={{
                    height: "21px",
                    gap: "0.25rem",
                    display: "flex",
                    alignItems: "center",
                    fontSize: 14,
                  }}
                >
                  {value}
                </Typography>
              )
            },
            {
              flex: 2,
              field: "actions",
              headerName: "",
              renderCell: ({ row }) => (
                <>
                  <Box>
                    {row.status === MultisignatureWalletTransactionStatus.AwaitingSignatures && (
                      <AddSignature transactionId={row.id} />)
                    }
                  </Box>
                  <Box>
                    {row.status === MultisignatureWalletTransactionStatus.AwaitingSignatures &&
                      !!row.tenderlySimulation && (

                        <Tooltip
                          title="View Transaction Simulation"
                        >
                          <IconButton
                            onClick={() =>
                              window.open(row.tenderlySimulation!, '_blank')}
                          >
                            <DetailsIcon />
                          </IconButton>
                        </Tooltip>
                      )
                    }</Box>
                </>
              )
            },
          ]}
        />
      </Box>
    </Box>
  );
};
