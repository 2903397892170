import { NavigationButton, Protector } from '@backed-fi/compound';
import { Box, Breadcrumbs, Link, Skeleton, Typography } from '@mui/material';
import { Link as RouterLink, Outlet, useLocation, useParams } from 'react-router-dom';
import React from 'react';
import { gql } from '@apollo/client';
import { useMultisignatureWalletOverviewQuery } from '@backed-fi/graphql';

const GraphQl = gql`
  query multisignatureWalletOverview($multisignatureWalletId: String!) {
    multisignatureWallet(id: $multisignatureWalletId) {
      title
      networks
    }
  }
`;

export const MultisignatureWalletDetailsLayout = () => {
  const location = useLocation();
  const params = useParams<{ id: string }>();

  // ---- Networking ---- //
  const { data, loading } = useMultisignatureWalletOverviewQuery({
    variables: {
      multisignatureWalletId: params.id!
    }
  });

  // ---- Helpers ---- //
  const getUrl = (section: string) => `/internal/multisignature/details/${params.id}/${section}`;

  // ---- Destructor ---- //
  const { multisignatureWallet } = data || {};

  return (
    <Protector>
      <Box
        sx={{
          height: 'calc(100vh - 4rem)',
          overflow: 'scroll',
          display: 'flex',
          flexFlow: 'column'
        }}
      >
        <Box
          sx={{
            mb: '2rem'
          }}
        >
          <Typography variant='title'>
            {location.pathname.includes('deploy') ? (
              <React.Fragment>
                Deploy contract
              </React.Fragment>
            ) : (
              <React.Fragment>
                {multisignatureWallet && multisignatureWallet.title}
                {loading && <Skeleton />}
              </React.Fragment>
            )}
          </Typography>

          <Breadcrumbs>
            <Link
              component={RouterLink}
              underline="hover"
              color="inherit"
              to="/"
            >
              Dashboard
            </Link>

            <Link
              component={RouterLink}
              underline="hover"
              color="inherit"
              to="/internal/multisignature/all"
            >
              Multisignature Wallets
            </Link>

            <Typography color="primary.textLowContrast">
              [{params.id}]
            </Typography>

            <Typography
              sx={{
                textTransform: 'capitalize'
              }}
            >
              {location.pathname.split('/').slice(-1)[0]}
            </Typography>
          </Breadcrumbs>
        </Box>

        <Box>
          <NavigationButton
            first
            to={getUrl('overview')}
            children="Overview"
          />
        </Box>

        <Box
          sx={{
            mt: '2rem',
            flex: 1
          }}
        >
          <React.Suspense fallback={'Loading the code...'}>
            <Outlet/>
          </React.Suspense>
        </Box>
      </Box>
    </Protector>
  );
};
