import React from 'react';
import { gql } from '@apollo/client';
import { Box, Button, Chip, IconButton, LinearProgress, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import ReceiptIcon from '@mui/icons-material/Receipt';
import HealingIcon from '@mui/icons-material/Healing';
import ReplayIcon from '@mui/icons-material/Replay';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';

import { PageHeading } from '@backed-fi/compound';
import { CustodyAccountFacetType, SettlementsListQuery, SettlementStatus, useSettlementsListQuery } from '@backed-fi/graphql';
import { CentsFormatter } from '@backed-fi/shared';
import { ManualSettlementCompletionDialog } from '../components/ManualSettlementCompletionDialog';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { usePaginationModel } from '@backed-fi/hooks';
import { format } from 'date-fns';
import { GenerateDvpDialog } from '../components/GenerateDvpDialog';

// region Graph

gql`
  query settlementsList(
    $page: Int,
    $pageSize: Int
  ) {
    settlements(
      page: $page,
      pageSize: $pageSize
    ) {
      nodes {
        id

        status

        startedAt
        executedAt

        fiatCurrency
        finalFiatDiff

        endOfDayFormDownloadUrl

        collateral {
          id

          symbol
          tokenCollaterals {
            custodyAccounts {
              type
              asset
              accountId
            }
          }
        }

        orders {
          id
          side
          totalShares
          pricePerShare

          interaction {
            client {
              name
            }
          }

        }
      }

      page {
        totalNodes
        totalPages
        currentPage
      }
    }
  }
`;

// endregion

const settlementStatusToChipColor = {
  Created: 'primary',
  Submitted: 'secondary',
  Executed: 'success'
} as const;

export const SettlementsListPage: React.FC = () => {
  const navigate = useNavigate();
  const {
    paginationVariables,
    ...pagination
  } = usePaginationModel();

  // region State

  const [execute, setExecute] = React.useState<string>();
  const [activeSettlementId, setActiveSettlementId] = React.useState<string>();

  // endregion

  // region Networking

  const query = useSettlementsListQuery({
    variables: paginationVariables
  });

  // endregion

  // region Actions

  const onGenerateDvpForm = (settlementId?: string) => {
    navigate({
      pathname: '/financials/brokerage/dvp',
      ...(settlementId ? {
        search: createSearchParams({
          settlementId
        }).toString()
      } : {})
    });
  };

  const onOpenExecuteDialog = (row: any) => () => {
    setExecute(row.collateral.custodyAccounts.find((x: any) => x.type === CustodyAccountFacetType.Fiat).accountId!);
    setActiveSettlementId(row.id);
  };

  const onDismissExecuteDialog = (shouldRefetch = false) => {
    if (shouldRefetch) {
      query.refetch()
        .then(() => {
          setExecute(undefined);
          setActiveSettlementId(undefined);
        });
    } else {
      setExecute(undefined);
      setActiveSettlementId(undefined);
    }
  };

  const data = (query.data ? query.data?.settlements : {}) as SettlementsListQuery['settlements'];

  // endregion

  return (
    <Box>
      {/* region Execution Dialog */}

      {(execute && activeSettlementId) && (
        <ManualSettlementCompletionDialog
          custodyAccountId={execute}
          settlementId={activeSettlementId}
          onDismiss={onDismissExecuteDialog}
        />
      )}

      {/* endregion */}

      <PageHeading
        title="Settlements (DVP)"
        breadcrumbs={[{
          text: 'Brokerage'
        }, {
          text: 'Settlements'
        }]}
      >
        <GenerateDvpDialog></GenerateDvpDialog>
      </PageHeading>

      <DataGrid
        {...pagination}

        autoHeight
        getRowHeight={({ model }) => 52 * (model.orders?.length ?? 1)}
        loading={query.loading}
        rows={data.nodes ?? []}
        rowCount={data.page?.totalNodes}
        components={{
          LoadingOverlay: LinearProgress
        }}
        columns={[
          {
            width: 150,
            field: 'collateral',
            headerName: 'Collateral',
            renderCell: ({ row }) => (
              <Typography>
                {row.collateral.symbol}
              </Typography>
            )
          }, {
            width: 150,
            field: 'status',
            headerName: 'Status',
            renderCell: ({ value }) => (
              <Chip
                label={value}
                color={settlementStatusToChipColor[value as SettlementStatus] ?? 'default'}
              />
            )
          }, {
            width: 150,
            field: 'startedAt',
            headerName: 'Started At',
            renderCell: ({ value }) => (
              <Typography>
                {format(new Date(value), 'yyyy-MM-dd')}
              </Typography>
            )
          }, {
            width: 150,
            field: 'executedAt',
            headerName: 'Executed At',
            renderCell: ({ value }) => (
              <Typography>
                {value ? format(new Date(value), 'yyyy-MM-dd') : 'Not Executed'}
              </Typography>
            )
          }, {
            width: 450,
            field: 'orders',
            headerName: 'Orders Included',
            renderCell: ({ value }) => (
              <List>
                {(value as (typeof data)['nodes'][number]['orders']).map((x) => (
                  <ListItem key={x.id} disablePadding>
                    <ListItemButton onClick={() => navigate(`/financials/brokerage/orders/${x.id}`)}>
                      <ListItemIcon>
                        <ArrowOutwardIcon />
                      </ListItemIcon>
                      <ListItemText primary={`${x.side} ${x.totalShares} (${CentsFormatter.format(x.totalShares * (Number(x.pricePerShare)))}$) by ${x.interaction.client.name
                        }`} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            )
          }, {
            width: 150,
            field: 'fiatCurrency',
            headerName: 'Fiat Currency',
            renderCell: ({ value }) => (
              <Typography>
                {value}
              </Typography>
            )
          }, {
            width: 150,
            field: 'finalFiatDiff',
            headerName: 'Final Fiat Diff',
            renderCell: ({ value }) => (
              <Typography>
                {
                  value
                    ? CentsFormatter.format(value)
                    : 'Not Available'
                }
              </Typography>
            )
          },
          {
            width: 100,
            field: 'actions',
            headerName: 'Actions',
            headerAlign: 'right',
            sortable: false,
            renderCell: ({ row }) => (
              <Box
                sx={{
                  flex: 1,
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}
              >

                {(row.status !== SettlementStatus.Executed) && (
                  <Tooltip
                    title='Complete order manually'
                  >
                    <IconButton
                      onClick={onOpenExecuteDialog(
                        row)}
                    >
                      <HealingIcon />
                    </IconButton>
                  </Tooltip>
                )}
                {row?.endOfDayFormDownloadUrl && (
                  <Tooltip
                    title='Download DVP Form'
                  >
                    <IconButton
                      onClick={() => {
                        window.open(row.endOfDayFormDownloadUrl!, '_blank');
                      }}
                    >
                      <ReceiptIcon />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip
                  title='Generate DVP Form'
                >
                  <IconButton
                    onClick={() => onGenerateDvpForm(
                      row.id)}
                  >
                    <ReplayIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            )
          }
        ]}
      />

      {/* endregion */}
    </Box>
  );
};
