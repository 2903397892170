import { Box, Button, Dialog, DialogContent, TextField } from '@mui/material';
import React from 'react';
import { gql } from '@apollo/client';
import { TokenCollateralizationQuery, useTokenCollateralizationLazyQuery, useUpdateTokenMultiplierMutation, TokenMultiplierChangeReason, AdminPermission } from '@backed-fi/graphql';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useToggle } from '@backed-fi/hooks';
import { InfoLabel, Protector, Title } from '@backed-fi/compound';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import Decimal from 'decimal.js';

// region Graph

gql`
  mutation updateTokenMultiplier($input: UpdateTokenMultiplierInput!) {
    updateTokenMultiplier(input: $input)
  }
`;

// endregion

// region Props

interface Props {
  tokenId: string;
}

// endregion

const Schema = z.object({
  changeBips: z.string().refine(
    (x) => {
      try {
        const parsed = new Decimal(x)
        return parsed.isFinite();
      } catch {
        return false;
      }
    },
    "Value is not a number"
  ).refine(x => {
    const parsed = new Decimal(x)
    return parsed.gt(-1_00_00) && parsed.lte(100_00_00)
  }, "Value outside of reasonable bounds"),
  reason: z.nativeEnum(TokenMultiplierChangeReason)
});

type Schema = z.infer<typeof Schema>;

export const UpdateMultiplierDialog: React.FC<Props> = ({ tokenId }) => {
  const dialog = useToggle();
  const snackbar = useSnackbar();

  const [updateMultiplier, { loading }] = useUpdateTokenMultiplierMutation();
  const [fetchData, { data }] = useTokenCollateralizationLazyQuery();

  const { token } = (data ?? {}) as TokenCollateralizationQuery;

  const form = useForm<Schema>({
    resolver: zodResolver(Schema),
  });
  const {
    errors
  } = form.formState;
  const changeBips = form.watch('changeBips');
  // region Effect

  React.useEffect(() => {
    if (dialog.open) {
      fetchData({
        variables: {
          tokenId
        }
      });
    }
  }, [dialog.open]);

  // endregion

  // region On Approve

  const onApprove = form.handleSubmit(async ({ changeBips, reason }) => {
    try {
      await updateMultiplier({
        awaitRefetchQueries: true,
        refetchQueries: [
          'tokenMultiplierChanges'
        ],
        variables: {
          input: {
            tokenId,
            changeBips,
            reason
          }
        }
      });

      snackbar.enqueueSnackbar('Successfully proposed new multiplier', {
        variant: 'success'
      });
    } catch (e: any) {
      snackbar.enqueueSnackbar(e.message ?? 'An error occurred while proposing new multiplier', {
        variant: 'error'
      });
    } finally {
      dialog.setFalse();
    }
  });

  // endregion

  return (
    <Protector permissions={[AdminPermission.BlockchainManage, AdminPermission.SystemManage]}>
      <Button onClick={dialog.setTrue}>
        Update Multiplier
      </Button>

      {dialog.open && token && (
        <Dialog
          {...dialog}
          fullWidth
          maxWidth="sm"
        >
          <DialogContent>
            <Title
              title="Update Multiplier"
              subtitle="Define percentage change in token balance (in bips increase/decrease)"
            />

            {/* region Labels */}
            {token.deployments.map(deployment => (
              <InfoLabel
                key={deployment.network}
                label={`Current ${deployment.network} multiplier`}
                content={new Decimal(deployment.currentMultiplier).div(Decimal.pow(10, 18)).toString()}
              />
            ))}

            <TextField
              select
              fullWidth
              label="Reason"
              error={!!errors?.reason}
              helperText={errors?.reason?.message}
              {...form.register('reason')}
            >
              <option disabled>
                {'Please select'}
              </option>

              {Object.values(TokenMultiplierChangeReason).filter(x => x !== TokenMultiplierChangeReason.FeeAccrual).map((x) => (
                <option
                  key={x}
                  value={x}
                >
                  {x}
                </option>
              ))}
            </TextField>
            <TextField
              size="small"
              label="Change of multiplier in bips"
              type="number"
              sx={{
                marginRight: '16px',
                width: '100%'
              }}

              error={!!errors.changeBips}
              helperText={errors.changeBips?.message}
              {...form.register('changeBips')}
            />

            {token.deployments.map(deployment => (
              <InfoLabel
                key={deployment.network}
                label={`New ${deployment.network} multiplier`}
                content={new Decimal(deployment.currentMultiplier).mul(new Decimal(changeBips || 0).plus(1_00_00)).div(Decimal.pow(10, 18 + 4)).toString()}
              />
            ))}

            {/* endregion */}

            <Box
              sx={{
                gap: '.5rem',
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <LoadingButton
                color="secondary"
                disabled={loading}
                onClick={dialog.setFalse}
              >
                Later
              </LoadingButton>

              <LoadingButton
                loading={loading}
                onClick={onApprove}
              >
                Propose
              </LoadingButton>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </Protector>
  );
};
