import { Box, Button, Dialog, DialogContent, TextField } from "@mui/material";
import React from "react";
import { gql } from "@apollo/client";
import {
  AdminPermission,
  BlockchainNetwork,
  useMultisignatureWalletOverviewQuery,
  useProposeMultisignatureWalletTransactionMutation,
} from "@backed-fi/graphql";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import { useToggle } from "@backed-fi/hooks";
import { Protector, Title } from "@backed-fi/compound";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

// region Graph

gql`
  mutation proposeMultisignatureWalletTransaction(
    $input: ProposeMultisignatureWalletTransactionInput!
  ) {
    proposeMultisignatureWalletTransaction(input: $input)
  }
`;

// endregion

// region Props

interface Props {
  walletId: string;
}

// endregion

const Schema = z.object({
  network: z.string().nonempty(),
  description: z.string(),
  payload: z.string()
});

type Schema = z.infer<typeof Schema>;

export const ProposeTransactionDialog: React.FC<Props> = ({ walletId }) => {
  const dialog = useToggle();
  const snackbar = useSnackbar();

  const { data, loading: dataLoading } = useMultisignatureWalletOverviewQuery({
    variables: {
      multisignatureWalletId: walletId
    }
  });

  const [proposeMultisignatureWalletTransaction, { loading }] =
    useProposeMultisignatureWalletTransactionMutation();

  const form = useForm<Schema>({
    resolver: zodResolver(Schema),
  });
  const { errors } = form.formState;

  // region Effect

  // endregion

  // region On Approve

  const onApprove = form.handleSubmit(
    async ({ network, description, payload }) => {
      try {
        await proposeMultisignatureWalletTransaction({
          awaitRefetchQueries: true,
          refetchQueries: ["multisignatureWalletTransactions"],
          variables: {
            input: {
              network: network as BlockchainNetwork, description, payload: JSON.parse(payload), walletId
            },
          },
        });

        snackbar.enqueueSnackbar(
          "Successfully proposed new multisignature transaction",
          {
            variant: "success",
          }
        );
      } catch (e: any) {
        snackbar.enqueueSnackbar(
          e.message ??
          "An error occurred while proposing new multisignature transaction",
          {
            variant: "error",
          }
        );
      } finally {
        dialog.setFalse();
      }
    }
  );

  // endregion

  return (
    <Protector
      permissions={[
        AdminPermission.BlockchainManage,
        AdminPermission.SystemManage,
      ]}
    >
      <Button onClick={dialog.setTrue}>Propose new transaction</Button>

      {dialog.open && (
        <Dialog {...dialog} fullWidth maxWidth="sm">
          <DialogContent>
            <Title
              title="Propose new transaction"
              subtitle="Please provide transaction details"
            />

            <TextField
              select
              label='Network'
              {...form.register('network')}
              SelectProps={{
                native: true
              }}
              fullWidth
            >
              <option disabled selected>
                Please select
              </option>

              {data?.multisignatureWallet.networks.map((network) => {
                return (
                  <option key={network} value={network}>
                    {network}
                  </option>
                );
              })}
            </TextField>

            <TextField
              size="small"
              label="Description of transaction"
              type="string"
              sx={{
                marginRight: "16px",
                width: "100%",
              }}
              error={!!errors.description}
              helperText={errors.description?.message}
              {...form.register("description")}
            />

            <TextField
              size="small"
              label="JSON payload of transaction"
              type="string"
              sx={{
                marginRight: "16px",
                width: "100%",
              }}
              error={!!errors.payload}
              helperText={errors.payload?.message}
              {...form.register("payload")}
            />

            {/* endregion */}

            <Box
              sx={{
                gap: ".5rem",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <LoadingButton
                color="secondary"
                disabled={loading}
                onClick={dialog.setFalse}
              >
                Later
              </LoadingButton>

              <LoadingButton loading={loading} onClick={onApprove}>
                Propose
              </LoadingButton>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </Protector>
  );
};
